.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#faceoutput, #canvasOutput {
  -o-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

a {
  text-decoration: none;
  color: #000000;
}

.react-date-picker__wrapper{
  border: none !important;
}
.webbrowser{
  width: 95%;
  padding: 0 0 0 5%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  justify-content: flex-start;
  align-items: center;
}
.Leftcol{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
}
.RightCol{
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}
.RightCol img{
  width: 90%;
}
.PortraitMode{
  display: none;
  flex-direction: column;
  width: 100%;
  background-color: #fafafa;
  position: fixed;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.recharts-wrapper{
  position: absolute;
  width:100% !important;
  left: -55px;
  top: -85px;
}
.recharts-wrapper svg{
  width: 100% !important;
  height: 100% !important;
}
.PortraitMode img{ width: 100px;}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)
and (orientation : portrait) { 
.Leftcol h3{
  font-size: 30px !important;
}
.webbrowser{
  display: none;
}
}
@media screen and (max-device-width: 991px) and (orientation: landscape) {
  .webbrowser{
    display: none;
  }
  .PortraitMode{
    display: flex;
  }
}
@media screen and (max-device-width: 767px) and (orientation: portrait) {
  .PortraitMode{
    display: none;
  }
  .webbrowser{
    display: none;
  }
}
/* HIDE RADIO */
.button{
  width: 31%;
}
.button input[type="radio"] {
  display: none;
}

.button input[type="radio"]:checked + label {
  border: 1px solid #2f99f1;
}
.button .genderLabel{
  width: 100%;
  border:1px #c8ced3 solid;
  border-radius: 10px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
}
.button img{
  width:25px;
  background: linear-gradient(#d7edff 0%, #fff 92.7%);
  height: 25px;
  border-radius: 50%;
  padding: 3px;
}
.react-date-picker__button{
  margin-right: 15px;
}




